  import Login from '@/components/login/index.vue'
  import Exames from '@/components/exames/index.vue'
  import ExamesPdf from '@/components/exames/pdf/index.vue'
  import Processamento from '@/components/preorders/index.vue'
  import BuscaCpf from '@/components/exames-cpf/index.vue'
  import Admin from '@/components/admin/index.vue'
  import MedicoLaudar from '@/components/medico/index.vue'
  import MedicoHome from '@/components/medico-home/index.vue'
  import MedicoValidate from '@/components/medico-validate/index.vue'
  import ExamesPendentes from '@/components/exames-pendentes/index.vue'
  import Pacientes from '@/components/pacientes/index.vue'
  import RadioReport from '@/components/medico/radioReport/index.vue'
  import PacienteDetalhes from '@/components/paciente-detalhes/index.vue'
  import PacienteExame from '@/components/paciente-exame/index.vue'
  import ExameHeatmap from '@/components/exame-heatmap/index.vue'
  import i18n from '@/i18n'

  const routes = [
    {
      path: '/',
      component: Login,
      name: 'login',
      meta: { requiresAuth: false }
    },
    {
      path: '/exames',
      component: Exames,
      meta: { requiresauth: true, requireredirect: true, roles: ['source'] },
      name: 'exames'
    },
    {
      path: '/exames/pdf',
      component: ExamesPdf,
      meta: { requiresauth: true, requireredirect: true, roles: ['source', 'physician', 'analyst'] },
      name: 'examesPdf',
      props: (route) => {
        return {
          id: route.query.id,
          isLaudo: route.query.isLaudo,
          pdfUrl: route.query.pdfUrl
        }
      }
    },
    {
      path: '/processamento-arquivos',
      component: Processamento,
      meta: { requiresauth: true, requireredirect: true, roles: ['source'] },
      name: 'processamento-arquivos'
    },
    {
      path: '/medico',
      name: 'medicoHome',
      component: MedicoHome,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true },
      menu: {
        name: 'Home'
      }
    },
    {
      path: '/busca-por-cpf',
      component: BuscaCpf,
      meta: { requiresauth: true, requireredirect: true, roles: ['source'] },
      name: 'busca-por-cpf'
    },
    {
      path: '/exames-pendentes',
      name: 'examesPendentes',
      component: ExamesPendentes,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true },
      menu: {
        name: i18n.t('queues')
      }
    },
    {
      path: '/pacientes',
      name: 'pacientes',
      component: Pacientes,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true },
      menu: {
        name: i18n.t('reports_finished')
      }
    },

    //TODO: CHANGE TO FLAG LISTEXAMS

    {
      path: '/laudar-radio',
      name: 'laudarRadio',
      component: RadioReport,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true },
      menu: {
        name: 'Laudar Radio'
      }
    },
    {
      path: '/medico/laudar',
      name: 'medicoLaudar',
      component: MedicoLaudar,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: false },
      menu: {
        name: i18n.t('to_report')
      }
    },
    {
      path: '/medico/validate',
      name: 'medicoValidate',
      component: MedicoValidate,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'] }
    },
    {
      path: '/pacientes/:id',
      name: 'paciente-detalhes',
      component: PacienteDetalhes,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true }
    },
    {
      path: '/pacientes/:patientId/exames/:examId',
      name: 'paciente-exame',
      component: PacienteExame,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true }
    },
    {
      path: '/exames/:id/heatmap',
      name: 'exame-heatmap',
      component: ExameHeatmap,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['physician'], needsManagerPhysician: true }
    },
    {
      path: '/analista',
      name: 'analista',
      component: MedicoLaudar,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['analyst', 'physician'] }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['admin', 'adminPhysician'] },
      beforeEnter: (to, from, next) => {
        window.location = `${window.location.origin}/admin/exames`
      }
    },
    {
      path: '/users',
      name: 'adminSource',
      component: Admin,
      meta: { requiresAuth: true, requireRedirect: true, roles: ['adminSource'] },
      beforeEnter: (to, from, next) => {
        window.location = `${window.location.origin}/admin/users`
      }
    }
  ]

  export default routes
