import moment from 'moment'

export default {
  name: 'token-countdown',
  props: {
    token: {
      type: Boolean,
      required: true
    },
    tokenExpiration: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timeRemaining: ''
    }
  },
  mounted() {
    if (this.token) {
      this.calculateTimeRemaining()
      this.interval = setInterval(this.calculateTimeRemaining, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    calculateTimeRemaining() {
      const expiration = moment(this.tokenExpiration)
      const now = moment()
      const duration = moment.duration(expiration.diff(now))
    
      if (duration.asSeconds() <= 0) {
        clearInterval(this.interval)
        this.timeRemaining = this.$t('token.expired_token')
        this.$emit('tokenExpired')
      } else {
        const days = duration.days()
        const hours = duration.hours()
        const minutes = duration.minutes()
        const seconds = duration.seconds()
      
        this.timeRemaining = `${days}d ${hours}h ${minutes}m ${seconds}s`
      }
    }
  }
}
