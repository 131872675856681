import RepetirExame from '@/components/medico/repetir-exame/index.vue'
import CategoryMixin from '@/shared/mixins/category.mixin'
import ReportCountdown from '@/components/medico/report-countdown/index.vue'
import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import ExamInformationMixin from '@/shared/mixins/exam-information.mixin'
import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import CONSTANTS from '@/shared/constants/constants'
import ExamDetailsAlert from '@/components/exames/exams-details/exam-details-alert/index.vue'
import TokenCountdown from '@/components/medico/token-countdown/index.vue'

export default {
  name: 'medico-header',
  components: {
    RepetirExame,
    ReportCountdown,
    ExamDetailsAlert,
    TokenCountdown
  },
  mixins: [
    CategoryMixin,
    DateMixin,
    ExamInformationMixin,
    ExamUrgencyAlertMixin,
    RoleMixin,
    AuthMixin,
    MedicalReportMixin
  ],
  props: {
    exam: {
      type: Object,
      default: {}
    },
    hasCountdown: {
      type: Boolean,
      default: true
    },
    hasInformation: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      creationTime: Object,
      performLabel: '',
      categorySvg: '',
      pixeonUrl: import.meta.env.VITE_URL_PIXEON,
      informations: [],
      roles: [],
      instructionText: this.$i18n.t('add_here_questions_related'),
      isOit: false,
      isCategoryXray: false,
      isCanAlertUrgency: false,
      token: false,
      tokenExpiration: '',
      usePtmSigner: false,
      showTokenExpiredModal: false,
    }
  },
  computed: {
    currentUser () {
      const user = this.getUser()
      const currentRole = this.roles.find((role) => {
        return user.role.name === role.name
      })

      if (!currentRole) {
        return {}
      }

      return {
        name: user.fullname,
        role: currentRole.label
      }
    },
    formattedInformations () {
      return this.informations.map((information) => {
        const role = this.roles.find((role) => {
          return information.role === role.name
        })

        information.role = role.label
        information.creationDate = this.getFormattedDateHour(information.creationDate)
        return information
      })
    },
    hasTimelineAccess () {
      const user = this.getUser()
      return user.role.name === 'physician' && user.physician && !user.physician.onlyReports
    }
  },
  async mounted () {
    this.$root.$emit('showLoading')
    try {
      await this.load()
      const res = await Promise.all(
        [this.getRoles(),
        this.getInformationsByExamId(this.exam.examId),
        this.getCanAlertBySource(this.exam.examId),
        this.getValidateCertificate()])
      this.roles = res[0]
      this.informations = res[1].data
      this.isOit = this.exam.specialityId === CONSTANTS.SPECIALITY.RAIOXOIT
      this.isCategoryXray = this.exam.categorySpecialtyId === CONSTANTS.CATEGORYSPECIALTY.XRAY
      this.isCanAlertUrgency = res[2]
      this.usePtmSigner = res[3].usePtmSigner
      this.token = res[3].token
      if (res[3].tokenExpiration) {
        this.tokenExpiration = res[3].tokenExpiration.toISOString()
      } else {
        this.tokenExpiration = ''
      }
    } finally {
      this.$root.$emit('hideLoading')
    }
  },
  methods: {
    async load () {
      this.creationTime = this.getDateDifferenceDays()
      this.performLabel = this.$i18n.tc(`time.${this.creationTime.label}`, this.creationTime.value)
      this.categorySvg = await this.getIconByCategoryForMedicHeader(this.exam);

      if (this.exam.pixeonUid) {
        this.pixeonUrl += this.exam.pixeonUid
      }
    },
    getDateDifferenceDays () {
      const initialDate = new Date(this.exam.creationDate)
      const today = new Date()
      const days = this.toDays(this.toUTC(today) - this.toUTC(initialDate))
      if (days) {
        return {
          value: days,
          label: 'day'
        }
      }

      const hours = today.getUTCHours() - initialDate.getUTCHours()
      if (hours) {
        return {
          value: hours,
          label: 'hour'
        }
      }

      const minutes = today.getUTCMinutes() - initialDate.getUTCMinutes()
      if (minutes) {
        return {
          value: minutes,
          label: 'minute'
        }
      }

      const seconds = today.getUTCSeconds() - initialDate.getUTCSeconds()
      return {
        value: seconds,
        label: 'second'
      }
    },
    toDays (date) {
      date = date || 0
      return date / 24 / 60 / 60 / 1000
    },
    toUTC (date) {
      if (!date || !date.getFullYear) {
        return 0
      }
      return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    },
    repeatExam (data) {
      this.$emit('repeatExam', data)
    },
    unassignExam (data) {
      this.$emit('unassignExam', data)
    },
    restartCountDown () {
      if (this.hasCountdown) {
        this.$refs.reportCountDown.restartCountDown()
      }
    },
    stopCountDown () {
      if (this.hasCountdown) {
        this.$refs.reportCountDown.stopCountDown()
      }
    },
    getClassButtons () {
      return {
        'repeat-exam-2-buttons': this.exam.pixeonUid
      }
    },
    hasSendInformation () {
      const user = this.getUser()
      return user.role.name === 'physician'
    },
    async clickSendInformation (information) {
      this.$root.$emit('showLoading')
      try {
        await this.sendInformation({
          examId: this.exam.examId,
          text: information
        })

        this.stopCountDown()
        const res = await this.hasMoreExams()
        const hasMoreExams = res.data
        this.$emit('sendInformation', {
          hasMoreExams,
          type: 'sendInformation'
        })
        this.$refs.information.hide()
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    showFile () {
      const file = this.exam.examFiles[0]
      const route = this.$router.resolve({ name: 'examesPdf', query: { id: file.examFileId, isLaudo: false } })
      window.open(route.href, '_blank')
    },
    handleTokenExpired () {
      if (this.usePtmSigner) {
        localStorage.removeItem('certificateValidation')
        sessionStorage.removeItem('isValidatedPhysician')
        this.showTokenExpiredModal = true
      }
    },
    handleModalConfirm () {
      this.showTokenExpiredModal = false
      this.logoutUser()
    }
  }
}
