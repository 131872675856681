import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import SpecialtyMixin from '@/shared/mixins/specialty.mixin'
import StatusMixin from '@/shared/mixins/status.mixin'
import ExamMixin from '@/shared/mixins/exam.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import WebChatVollMixin from '@/shared/mixins/WebChatVoll.mixin'
import Filter from '@/shared/filter/filter'
import CONSTANTS from '../../shared/constants/constants'
import ExamDetailsHeader from '@/components/exames/exams-details/exam-details-header/index.vue'
import ExamDetailsObservation from '@/components/exames/exams-details/exam-details-observation/index.vue'
import ExamDetailsFile from '@/components/exames/exams-details/exam-detais-file/index.vue'
import ExamDetailsInformation from '@/components/exames/exams-details/exam-details-information/index.vue'
import ExamDetailsSubheader from '@/components/exames/exams-details/exam-details-subheader/index.vue'
import ExamDetailsRepeat from '@/components/exames/exams-details/exam-details-repeat/index.vue'
import ExamNotificationUrgencyAlert from '@/components/exame-notification-urgency-alert/index.vue'
import ExamDetailsPopupNotification from '@/components/exames/exams-details/exam-details-popup-notification/index.vue'
import PendencyAlert from '@/components/exames/pendency-alert/index.vue'
import { mapActions, mapGetters } from 'vuex'
import CategoryMixin from '@/shared/mixins/category.mixin'
import alertaUrgenciaIcon from '@/assets/svg/status/alerta_urgencia.svg?raw'
import preordersIcon from '@/assets/svg/preorders.svg?raw'
import documentSearch from '@/assets/svg/document_search.svg?raw'
import reportIcon from '@/assets/svg/report.svg?raw'
import urgentIcon from '@/assets/svg/urgent.svg?raw'
import emergencyIcon from '@/assets/svg/emergency.svg?raw'
import LaudoLiberadoStatusIcon from '@/assets/svg/status/laudo_liberado.svg?raw'
import PendenciasStatusIcon from '@/assets/svg/status/pendencias.svg?raw'
import RepetirStatusIcon from '@/assets/svg/status/repetir.svg?raw'
import AguardandoLaudoStatusIcon from '@/assets/svg/status/aguardando_laudo.svg?raw'
import UrgencyIcon from '@/assets/svg/alert-test.svg?raw'
import patientIcon from '@/assets/svg/patient.svg?raw'
import fileIcon from '@/assets/svg/file-plus.svg?raw'
import infoIcon from '@/assets/svg/info.svg?raw'
import repeatIcon from '@/assets/svg/repeat.svg?raw'
import UrgencyAttentionIcon from '@/assets/svg/urgent-attention.svg?raw'

const EXAM_TAB = 0
const URGENT_TAB = 1
const EMERGENCY_TAB = 2

export default {
  name: 'exames',
  mixins: [
    DateMixin,
    RoleMixin,
    SpecialtyMixin,
    StatusMixin,
    ExamMixin,
    AuthMixin,
    ExamUrgencyAlertMixin,
    WebChatVollMixin,
    CategoryMixin
  ],
  components: {
    ExamDetailsHeader,
    ExamDetailsObservation,
    ExamDetailsFile,
    ExamDetailsInformation,
    ExamDetailsSubheader,
    ExamDetailsRepeat,
    ExamNotificationUrgencyAlert,
    ExamDetailsPopupNotification,
    PendencyAlert
  },
  data () {
    return {
      activeFilters: {},
      items: [],
      filteredSpecialties: [],
      itemsResolved: [],
      headers: [
        '',
        '',
        this.$i18n.t('header.name'),
        this.$i18n.t('header.status'),
        this.$i18n.t('header.category'),
        this.$i18n.t('header.exam_type'),
        this.$i18n.t('header.number'),
        this.$i18n.t('header.date')
      ],
      specialties: [],
      categories: [],
      status: [],
      total: 0,
      page: 1,
      pageSize: 10,
      selectedFilters: {},
      placeholderSearch: this.$i18n.t('placeholder_patient_name_or_exam_number'),
      maxCharChip: 50,
      selectedRows: [],
      selectAll: false,
      STATUS: CONSTANTS.STATUS,
      roles: [],
      defaultTabs: [
        {
          id: EXAM_TAB,
          title: this.$i18n.tc('report', 2),
          icon: reportIcon
        },
        {
          id: URGENT_TAB,
          title: this.$i18n.t('urgent'),
          icon: urgentIcon
        },
        {
          id: EMERGENCY_TAB,
          title: this.$i18n.t('emergency'),
          icon: emergencyIcon
        }
      ],
      details: null,
      periodItems: [],
      period: {},
      examInfos: [],
      defaultExamInfos: [
        { id: 10, name: this.$i18n.t('released_report'), totalExams: '...', icon: LaudoLiberadoStatusIcon },
        { id: 300, name: this.$i18n.tc('pendencies', 1), totalExams: '...', icon: PendenciasStatusIcon },
        { id: 95, name: this.$i18n.t('repeat'), totalExams: '...', icon: RepetirStatusIcon },
        { id: 22, name: this.$i18n.t('waiting_report'), totalExams: '...', icon: AguardandoLaudoStatusIcon }
      ],
      showResumePanel: true,
      activeTab: 0,
      startSlideDate: '',
      endSlideDate: '',
      activeDetailsTab: 0,
      instructionText: this.$i18n.t('portal_telemedicina_requires_related_information'),
      DETAILS_PATIENT_TAB: 0,
      DETAILS_FILE_TAB: 1,
      DETAILS_INFORMATION_TAB: 2,
      DETAILS_REPEAT_TAB: 3,
      printIcon: '/assets/img/print-icon.png',
      printedOptions: [
        {
          id: true,
          name: this.$i18n.t('yes')
        },
        {
          id: false,
          name: this.$i18n.t('no')
        }
      ],
      showCustom: false,
      customStartDate: '',
      customEndDate: '',
      payloadPopupNotification: {},
      openPopupNotification: false,
      infoUrgencyAlert: {
        id: 104,
        name: this.$i18n.t('urgency_alert'),
        totalExams: '...',
        icon: alertaUrgenciaIcon
      },
      listUrgencyAlert: [],
      qtdPendency: 0,
      showObservationTab: true,
      seenPendencyAlert: false,
      iconPreorders: preordersIcon,
      iconDocumentSearch: documentSearch,
      preordersTooltip: this.$i18n.t('tooltips.preorders'),
      documentSearchTooltip: this.$i18n.t('tooltips.documentSearch'),
      isAllowedPreOrder: false,
      hasGroup: false,
      urgencyIcon: UrgencyIcon,
      urgencyTooltip: this.$i18n.t('tooltips.urgency_alert'),
      urgencyAttentionIcon: UrgencyAttentionIcon,
      urgencyAttentionTooltip: this.$i18n.t('emergency-alert-occurred')
    }
  },
  computed: {
    ...mapGetters({
      getUpdateGridExams: 'getUpdateGridExams',
      getSidebarStatus: 'getSidebarStatus'
    }),
    examCount () {
      return this.getExamCount()
    },
    examCountDisplayNumber () {
      const fives = Math.floor(this.getExamCount() / 5)
      return fives > 0 ? `(+${5 * fives})` : ''
    },
    exams () {
      return this.items.map(item => {
        return {
          printed: item.printed,
          selectable: item.status.id === this.STATUS.LAUDO_LIBERADO,
          patientName: item.patientName,
          status: item.status.id === 18 || item.status.id === 26 ? {id: 300, name: this.$i18n.t('pending')} : this.getStatusById(item.status.id),
          category: {
            name: item.category.name,
            icon: item.category.icon
          },
          speciality: item.speciality.name,
          id: item.examId,
          creationDate: this.getFormattedDateHour(item.creationDate),
          dtUltAtu: this.getFormattedDateHour(item.dtUltAtu),
          class: this.getClasses(item),
          hasUrgency: item.urgencyAlert,
          hasUrgencyAttention: item.urgencyAlert
        }
      })
    },
    statusIcon () {
      const status = this.defaultExamInfos.find((status) => {
        return status.id === this.details.status.id
      })

      return status ? status.icon : this.defaultExamInfos[2].icon
    },
    detailsTabs () {
      const tabs = [
        {
          title: this.$i18n.tc('multiple.patient', 1),
          icon: patientIcon,
          id: 'tab-patient',
          show: this.showObservationTab
        },
        {
          title: this.$i18n.t('files'),
          icon: fileIcon,
          id: 'tab-file',
          show: true
        },
        {
          title: this.$i18n.tc('multiple.information', 1),
          icon: infoIcon,
          id: 'tab-information',
          show: true
        },
        {
          title: this.$i18n.t('repeat_reason'),
          icon: repeatIcon,
          id: 'tab-repeat',
          show: true
        }
      ]
      if (this.details) {
        if (!this.details.repeatReason) {
          tabs[3].show = false
        }
      }
      return tabs
    },
    tabs () {
      const tabs = this.defaultTabs.filter(item => {
        switch (item.id) {
          case EXAM_TAB:
            return !this.isEmergencySource()
          case URGENT_TAB:
            return !this.isEmergencySource()
          case EMERGENCY_TAB:
            return this.isEmergencySource()
          default:
            return true
        }
      })
      return tabs
    },
    hasPendency () {
      return this.qtdPendency !== 0
    }
  },
  watch: {
    getUpdateGridExams (newValue) {
      if (newValue) {
        this.updateGridExams()
      }
    },
    getSidebarStatus (newValue) {
      if (newValue) {
        this.closeSidebar()
      }
    },
  },
  created () {
    if (process.env.NODE_ENV !== 'testing') {
      this.startWebChatVoll()
    }
  },
  async mounted () {
    const sourceDetails = await this.getSourceDetails()
    this.setEmergencySource(sourceDetails.isEmergency)
    this.isAllowedPreOrder = sourceDetails.preOrder
    this.hasGroup = sourceDetails.hasGroup

    this.examInfos = this.defaultExamInfos
    this.periodItems = [
      { id: this.all, name: this.$i18n.t('period.all') },
      { id: this.month, name: this.$i18n.t('period.month') },
      { id: this.week, name: this.$i18n.t('period.week') },
      { id: this.day, name: this.$i18n.t('period.day') },
      { id: this.custom, name: this.$i18n.t('period.custom') }
    ]
    const period = this.getDefaultPeriodFromType(this.periodItems[0].id)
    this.setPeriod(this.periodItems[0].id, period.startDate, period.endDate)

    this.status = await this.getStatus()
    this.specialties = await this.getSpecialties()
    this.updateFilteredSpecialties()

    this.loadUrgencyAlerts()
    this.$root.$on('WebSocketUrgencyAlert', () => this.loadUrgencyAlerts())
    this.$root.$on('selectSource', () => this.onSelectSource())

    this.unsetPrintedReports()
    this.getStatusCounters()
    await this.resetCategories()
  },
  methods: {
    ...mapActions(['changeUpdateGridExams', 'changeSidebarStatus', 'setExamCount']),
    ...mapGetters(['getExamCount']),
    isStartDateEqualsOfEndDate () {
      return this.diffDays(this.period.startDate, this.period.endDate) === 0
    },
    onSelectSource () {
      this.reloadExames()
    },
    setPeriod (type, startDate, endDate) {
      this.period.type = type
      this.period.startDate = startDate
      this.period.endDate = endDate
      this.period.startSlideDate = type !== 'all' && this.getTextDate(this.getAmericanDate(startDate))
      this.period.endSlideDate = type !== 'all' && this.getTextDate(this.getAmericanDate(endDate))
    },

    removeDiacritics (value) {
      return value.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    updateActiveFilters() {
      this.activeFilters.Page = { value: this.page - 1 }
      this.activeFilters.PageSize = { value: this.pageSize }
      this.activeFilters.ExamStartDate = { value: this.getAmericanDate(this.period.startDate) }
      this.activeFilters.ExamEndDate = { value: this.getAmericanDate(this.period.endDate) }
    },
    async getStatusCounters () {
      try {
        this.examInfos = this.defaultExamInfos
        this.$root.$emit('showNavbarLoading')
        this.updateActiveFilters()
        const counters = await this.getExamStatusCounters(this.activeFilters)
        const counterAlertUrgency = await this.getExamAlertCounters(this.activeFilters?.ExamStartDate?.value,
          this.activeFilters?.ExamEndDate?.value)

        if (counterAlertUrgency) {
          this.infoUrgencyAlert.totalExams = counterAlertUrgency.totalExams
        }

        if (counters) {
          this.examInfos = this.customizeCounters(counters.data)
          this.examInfos.map((item) => {
            const statusId = Object.keys(CONSTANTS.STATUS).find(key => CONSTANTS.STATUS[key] === item.id)
            const status = statusId ? statusId.toLowerCase() : this.removeDiacritics(item.name).replace(' ', '_')
            item.icon = `<img src="/assets/svg/status/${status}.svg?raw" class='exam-status-icon-${status}' />`
            item.totalExams = `${item.totalExams}`
            if (item.id === 300) {
              item.totalExams >= 2 ? item.name = this.$i18n.tc('pendencies', 2) : item.name = this.$i18n.tc('pendencies', 1)
            } else {
              item.name = this.$i18n.t(CONSTANTS.TRANSLATION.STATUS[item.id])
            }
          })
        }
      } finally {
        this.$root.$emit('hideNavbarLoading')
      }
    },
    async updateCounters() {
      this.$root.$emit('showLoading')

      try {
        await this.getStatusCounters()

        this.$root.$emit('toast', { 
          message: this.$i18n.t('counter.update_success'), 
          type: 'success' 
        })
        
      } catch (error) {
        this.$root.$emit('toast', { 
          message: this.$i18n.t('counter.update_error'), 
          type: 'error' 
        })
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    customizeCounters (counters) {
      let sumExams = 0
      counters.forEach((value) => {
        if (value.id === 18 || value.id === 26) {
          sumExams += value.totalExams
        }
      })
      counters.push({ id: 300, name: this.$i18n.tc('pendencies', 1), totalExams: sumExams, notifications: [] })
      const statusCount = counters.filter((counter) => {
        return [10, 300, 95, 22].includes(counter.id)
      })

      const statusOrder = [10, 300, 95, 22]
      const countersOrdered = []

      statusOrder.forEach((item) => {
        const index = statusCount.findIndex((object) => object.id === item)
        countersOrdered.push(statusCount[index])
      })
      this.qtdPendency = sumExams
      return countersOrdered
    },

    async resetCategories () {
      const categories = await this.getCategory()
      this.categories = await Promise.all(categories.map(async category => {
        if (!category.svg) {
          category.svg = await this.getSvgForLinkClientId(category.linkClientId)
        }

        return category
      }))
    },

    async resetMenuFilters () {
      await this.resetCategories()
    },

    async getDetails (index) {
      const item = this.items[index]
      this.details = await this.getExamDetails(item.examId)
      this.setViewedRepeat(item)
      Object.assign(this.details, item)

      if (this.details.status.id === this.awaitingInformation || this.details.status.id === this.pendingNote) {
        this.showObservationTab = true
        this.setActiveDetailsTab(this.DETAILS_INFORMATION_TAB)
      } else if (this.details.status.id === this.repeat) {
        this.showObservationTab = false
        this.setActiveDetailsTab(this.DETAILS_REPEAT_TAB)
      } else {
        this.showObservationTab = true
        this.setActiveDetailsTab(this.DETAILS_PATIENT_TAB)
      }
      this.cancelUrgent()
    },

    setViewedRepeat (item) {
      if (item.status.id === 95) {
        this.setViewed(item.examId)
      }
    },

    selectPeriod (type) {
      if (type.id === this.custom) {
        this.showCustom = true
        return
      }
      if (type.id !== this.all && this.activeFilters.ExamId) {
        this.$refs.chipsComponent.removeChip('ExamId')
      }
      this.showCustom = false
      const period = this.getDefaultPeriodFromType(type.id)
      this.setPeriod(type.id, period.startDate, period.endDate)
    },
    selectPeriodAndReload (type) {
      this.selectPeriod(type)
      this.reloadExames()
    },
    setCustomPeriod () {
      if (!this.isValid(this.customStartDate)) {
        this.$root.$emit('toast', { message: this.$i18n.t('error.invalid_start_date'), type: 'info' })
        return
      }

      if (!this.isValid(this.customEndDate)) {
        this.$root.$emit('toast', { message: this.$i18n.t('error.invalid_end_date'), type: 'info' })
        return
      }

      if (this.diffDays(this.customEndDate, this.customStartDate) < 0) {
        this.$root.$emit('toast', { message: this.$i18n.t('error.invalid_period'), type: 'info' })
        return
      }

      this.setPeriod(this.custom, this.customStartDate, this.customEndDate)
      this.getStatusCounters()
      this.getExames()
    },

    getDefaultPeriodFromType (type) {
      let startDate = ''
      let endDate = ''
      switch (type) {
        case this.day:
          startDate = this.getToday()
          endDate = this.getToday()
          break
        case this.week:
          startDate = this.getStartWeek()
          endDate = this.getToday()
          break
        case this.month:
          startDate = this.getStartMonth()
          endDate = this.getToday()
          break
        default:
          break
      }
      return {
        startDate,
        endDate
      }
    },

    setPeriodFilter (startDate, endDate) {
      this.activeFilters.ExamStartDate = { value: startDate }
      this.activeFilters.ExamEndDate = { value: endDate }
    },

    isToday () {
      if (this.period.endDate) {
        const today = this.getToday()
        return this.diffDays(this.period.endDate, today) >= 0
      }
    },

    previousDate () {
      let startDate = ''
      let endDate = ''
      let month = ''
      switch (this.period.type) {
        case this.day:
          startDate = this.subtractDays(this.period.startDate, 1)
          endDate = startDate
          break
        case this.week:
          startDate = this.subtractDays(this.period.startDate, 7)
          endDate = this.addDays(startDate, 6)
          break
        case this.month:
          month = this.period.startDate.month()
          let year = this.period.startDate.year()
          month--
          if (month < 0) {
            month = 11
            year = year - 1
          }
          startDate = this.startMonth(month, year)
          endDate = this.endMonth(month, year)
          break
        default:
          break
      }
      this.setPeriod(this.period.type, startDate, endDate)
      this.getStatusCounters()
      this.getExames()
    },

    nextDate () {
      if (this.isToday()) {
        return
      }
      let startDate = ''
      let endDate = ''
      let month = ''
      switch (this.period.type) {
        case this.day:
          startDate = this.addDays(this.period.startDate, 1)
          endDate = startDate
          break
        case this.week:
          startDate = this.addDays(this.period.startDate, 7)
          endDate = this.addDays(startDate, 6)
          break
        case this.month:
          month = this.period.startDate.month()
          let year = this.period.startDate.year()
          month++
          if (month > 11) {
            month = 0
            year = year + 1
          }
          startDate = this.startMonth(month, year)
          endDate = this.endMonth(month, year)
          break
        default:
          break
      }
      const today = this.getToday()
      if (this.diffDays(endDate, today) >= 0) {
        endDate = today
      }
      this.setPeriod(this.period.type, startDate, endDate)
      this.getStatusCounters()
      this.getExames()
    },

    async getExames () {
      this.$root.$emit('showLoading')
      this.$refs.grid.hideDetails()

      try {
        this.items = []
        this.setFilters()
        const rawItems = await this.getExams(this.activeFilters)
        const page = rawItems.page
        const pageSize = rawItems.pageSize
        const totalExams = rawItems.totalExams

        if (totalExams / pageSize < page && page !== 0) {
          this.page--
          return this.getExames()
        }

        this.items = await Promise.all(rawItems.data.map(async item => {
          item.category.icon = await this.getSvgForLinkClientId(item.category.linkClientId)
          return item
        }))

        this.total = rawItems.totalExams
        return true
      } catch (errors) {
        return false
      } finally {
        this.$root.$emit('hideLoading')
        this.setExamCount(0)
      }
    },

    setFilters () {
      delete this.activeFilters.Urgent
      if (!this.showResumePanel) {
        delete this.activeFilters.ExamStartDate
        delete this.activeFilters.ExamEndDate
      } else {
        this.setPeriodFilter(this.getAmericanDate(this.period.startDate), this.getAmericanDate(this.period.endDate))
      }
      this.activeFilters.Page = { value: this.page - 1 }
      this.activeFilters.PageSize = { value: this.pageSize }

      const sources = JSON.parse(localStorage.getItem("user")).source
      if (sources.length > 1) {
        const selectedSourceId = (JSON.parse(localStorage.getItem("selectedSource"))).id
              || sources[0].id
        this.activeFilters.SourceId = { value: selectedSourceId }
      }

      if (this.activeTab === URGENT_TAB) {
        this.activeFilters.Urgent = { value: true }
      }
    },

    clearSelectedRows () {
      this.selectedRows = []
      this.selectAll = false
    },

    reloadExames () {
      this.clearSelectedRows()
      this.page = 1
      this.pageSize = 10
      this.getStatusCounters()
      return this.getExames()
    },

    changePagination (data) {
      this.clearSelectedRows()
      if (this.pageSize !== parseInt(data.pageSize)) {
        this.page = 1
      } else {
        this.page = parseInt(data.currentPage)
      }
      this.pageSize = parseInt(data.pageSize)
      this.getExames()
    },

    updateFilteredSpecialties() {
      this.filteredSpecialties = this.specialties.filter(s => {
        if (!this?.activeFilters?.CategoryId?.value)
          return true

        return s.categoryId === this.activeFilters.CategoryId.value
      })
    },

    async addFilter (filter, reload = true) {
      let filters = []
      if (Array.isArray(filter)) {
        filters = filter
      } else {
        filters.push(filter)
      }

      filters.forEach((filter) => {
        this.activeFilters[filter.key] = {
          value: filter.value,
          keyLabel: filter.keyLabel,
          valueLabel: filter.valueLabel
        }
      })

      this.updateFilteredSpecialties()

      if (!reload || await this.reloadExames()) {
        filters.forEach((filter) => {
          this.$refs.chipsComponent.addChip(filter.keyLabel, filter.valueLabel, filter.key)
        })
        this.$refs.search.clear()
      } else {
        filters.forEach((filter) => {
          delete this.activeFilters[filter.key]
        })
      }
    },

    setFilterUrgencyAlert () {
      this.addFilter(new Filter('UrgencyAlert', true, this.$i18n.t('urgency_alert'), this.$i18n.t('yes')))
    },
    deleteFilter (key) {
      if (key) {
        delete this.activeFilters[key]
        this.resetMenuFilters()
      }
      this.updateFilteredSpecialties()
      this.reloadExames()
    },
    showLaudo (examId) {
      this.setViewed(examId)
      const route = this.$router.resolve({ name: 'examesPdf', query: { id: examId } })
      window.open(route.href, '_blank')
    },

    search (value) {
      const isNumber = /^\d+$/.test(value)
    
      if (isNumber) {
        this.addFilter(new Filter('ExamId', value, this.$i18n.t('exam')), false)
      } else {
        this.addFilter(new Filter('PatientName', value, this.$i18n.tc('multiple.patient', 1)), false)
      }
    
      if (this.period && this.period.id !== this.all) {
        this.$refs.dateMenu.setTitle(this.period.name)
      } else {
        this.selectPeriod({ id: this.all, name: this.$i18n.t('period.all') })
        this.$refs.dateMenu.setTitle(this.$i18n.t('period.all'))
      }
      this.reloadExames()
    },    

    searchError (errorMessage) {
      if (errorMessage) {
        this.$root.$emit('toast', { message: errorMessage, type: 'info' })
      }
    },

    getFilterToSearch (key, value, keyLabel = null, valueLabel = value) {
      return {
        key,
        value,
        keyLabel,
        valueLabel
      }
    },

    setFilterSpecialty (item) {
      if (!item.id) {
        return
      }

      this.addFilter(this.getFilterToSearch('SpecialityId', item.id, this.$i18n.t('speciality'), item.name))
    },

    setFilterStatus (status) {
      this.addFilter(new Filter('StatusId', status.id, this.$i18n.t('header.status'), status.name))
    },

    setFilterCategory (category) {
      this.addFilter(this.getFilterToSearch('CategoryId', category.id, this.$i18n.t('header.category'), category.name))
      delete this.activeFilters.SpecialityId
      this.$refs.chipsComponent.removeChip('SpecialityId')
    },

    async getCategoriesBySpecialty (specialtyId = null) {
      this.categories = await this.getCategory(specialtyId)
      await Promise.all(this.categories.map(async (category) => {
        category.svg = await this.getSvgForLinkClientId(category.linkClientId)
      }))
    },

    clearFilters () {
      this.activeFilters = {}
      this.reloadExames()
    },

    orderbyPatientName (order) {
      this.activeFilters.OrderColumn = { value: 'PatientName' }
      this.activeFilters.OrderBy = { value: order }
      this.reloadExames()
    },

    setViewed (examId) {
      const item = this.exams.find((item) => {
        return examId === item.id
      })
      item.class.viewed = true
      item.class['not-viewed'] = false
      this.$forceUpdate()
    },

    getClasses (exam) {
      return {
        'urgent': exam.urgent,
        'viewed': exam.viewed,
        'needAttention': (exam.status.id === this.STATUS.AGUARDANDO_INFORMACAO || exam.status.id === this.STATUS.AGUARDANDO_OBSERVACAO) && !exam.viewed,
        'not-viewed': (exam.status.id === this.STATUS.LAUDO_LIBERADO ||
          exam.status.id === this.STATUS.REPETIR) && !exam.viewed
      }
    },

    selectAllRows () {
      if (this.selectAll) {
        this.selectedRows = this.exams.filter((item) => {
          return item.selectable
        })
      } else {
        this.selectedRows = []
      }
    },

    printLaudos () {
      if (this.hasPrintedReports()) {
        this.$root.$emit('toast', { message: this.$i18n.t('wait_there_are_files_being_printed'), type: 'info' })
      } else if (this.selectedRows.length) {
        if (this.selectedRows.length > 20) {
          this.$root.$emit('toast', { message: this.$i18n.t('reached_batch_print_limit'), type: 'warning' })
          return
        }
        this.setPrintedReports()
        this.$root.$emit('toast', { message: this.$i18n.t('preparing_files'), type: 'info' })

        const arrayIds = this.selectedRows.map((item) => {
          return item.id
        })

        arrayIds.forEach((examId) => {
          const item = this.items.find((item) => {
            return item.examId === examId
          })

          item.printed = true
        })

        const route = this.$router.resolve({ name: 'examesPdf', query: { id: JSON.stringify(arrayIds) } })
        window.open(route.href, '_blank')
      }
    },

    resetSelectAll () {
      this.selectAll = false
    },

    getHeader (index) {
      return this.headers[index]
    },

    changeTab (index) {
      this.activeTab = index
      this.getExames()
    },

    toggleResumePainel () {
      this.showResumePanel = !this.showResumePanel
      this.reloadExames()
    },

    changeDetailsTab (index) {
      this.cancelUrgent()
      this.activeDetailsTab = index
    },

    closeDetails () {
      this.setActiveDetailsTab(this.DETAILS_PATIENT_TAB)
    },

    setActiveDetailsTab (index) {
      this.activeDetailsTab = index
      this.$refs.grid.setActiveDetailsTab(this.activeDetailsTab)
    },

    updateStatus (statusId) {
      const exam = this.items.find((exam) => {
        return exam.examId === this.details.examId
      })
      if (exam.status.id !== statusId) {
        this.examInfos.forEach(item => {
          if (item.id === exam.status.id) {
            item.totalExams--
          } else if (item.id === statusId) {
            item.totalExams++
          }
        })
        exam.status.id = statusId
      }
    },

    updateUrgent (examId) {
      const item = this.items.find((item) => {
        return item.examId === examId
      })
      this.details.urgent = true
      item.urgent = true
    },

    cancelUrgent () {
      if (this.$refs.subheader) {
        this.$refs.subheader.cancelUrgent()
      }
    },

    sendInformation () {
      this.updateStatus(this.pendingReport)
    },

    hasAddObservation (index) {
      const item = this.items[index]
      const originalStatus = item.originalStatus.id
      if (item.observation) {
        return false
      }

      return !(originalStatus === this.STATUS.LAUDO_LIBERADO || originalStatus === this.STATUS.LAUDANDO)
    },
    setFilterPrinted (printed) {
      this.addFilter(new Filter('Printed', printed.id, this.$i18n.t('printed'), printed.name))
    },
    refreshFilters () {
      this.reloadExames()
    },
    showPopupNotification (payload) {
      this.payloadPopupNotification = payload
      this.openPopupNotification = true
    },
    closeSuccessPopupNotification () {
      const urgencyID = this.payloadPopupNotification.urgencyID
      this.listUrgencyAlert = this.listUrgencyAlert.filter(item => item.urgencyID !== urgencyID)
      this.payloadPopupNotification = {}
      this.openPopupNotification = false
    },
    closeCancelPopupNotification () {
      this.payloadPopupNotification = {}
      this.openPopupNotification = false
    },
    async loadUrgencyAlerts () {
      const array = await this.getAllNoCheckedAlerts()
      this.listUrgencyAlert = array.reverse()
    },
    hideAlert () {
      this.seenPendencyAlert = true
      localStorage.setItem('pendencyAlertDismissed', 'true')
      this.setFilterStatus({ id: 300, name: this.$i18n.tc('pendencies', 1) })
    },
    updateGridExams () {
      this.reloadExames()
      this.changeUpdateGridExams(false)
    },
    closeSidebar () {
      this.$refs.grid.hideDetails()
      this.changeSidebarStatus(false)
    },
    goToPreorders () {
      this.$router.push({ path: '/processamento-arquivos' })
    },
    goToDocumentSearch () {
      this.$router.push({ path: '/busca-por-cpf' })
    },
  }
}
